/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactElement, ReactNode, useEffect, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export const Nav = ({
  children,
}: {
  children: ReactNode;
  toggle: string;
}): ReactElement => {
  const [navbar, setNavbar] = useState(false);
  const Logo = require("../../../assets/images/logo.png");
  const navStyle =
    "text-black hover:text-eskane-blue hover:cursor-pointer font-poppins";

  const _home = () => {
    document.getElementById("home").scrollIntoView({
      behavior: "smooth",
    });
  };

  const _plans = () => {
    document.getElementById("fifa").scrollIntoView({
      behavior: "smooth",
    });
  };

  const _contact = () => {
    document.getElementById("contact").scrollIntoView({
      behavior: "smooth",
    });
  };

  const _navbarChange = () => setNavbar(!navbar);

  const ButtonMailto = ({ mailto, label }) => {
    return <a href={mailto}>{label}</a>;
  };

  const targetTime = moment("2023-12-25");
  const [currentTime, setCurrentTime] = useState(moment());
  const timeBetween = moment.duration(targetTime.diff(currentTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const openWhatsApp = () => {
    const phoneNumber = "+972049571191";
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  return (
    <div className="h-screen w-screen z-50 relative shadow-md">
      <nav className="w-full fixed z-50 bg-white shadow-md">
        <div className="justify-between px-4 mx-auto lg:px-24 md:items-center md:flex md:px-8">
          <div>
            <div className="flex items-center justify-between md:block h-16">
              <a onClick={_home}>
                <img
                  src={Logo}
                  alt="eskane"
                  className="w-36 hover:cursor-pointer self-center mt-3 ml-[-6px]"
                />
              </a>
              <div className="md:hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-black focus:border"
                  onClick={_navbarChange}
                >
                  {navbar ? (
                    <svg className="w-6 h-6" viewBox="0 0 20 20" fill="black">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6"
                      fill="black"
                      viewBox="0 0 24 24"
                      stroke="black"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="z-50">
            <div
              className={`flex-1 justify-self-center md:block md:mt-0 z-20 ${
                navbar ? "block" : "hidden"
              }`}
            >
              <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 md:mb-0 mb-6 ml-2 text-black mt-12 md:mt-0">
                <li onClick={_home} className={navStyle}>
                  <p>Home</p>
                </li>
                <li onClick={_plans} className={navStyle}>
                  <p>FIFA Tournament</p>
                </li>
                <li
                  onClick={_contact}
                  className={`hover:cursor-pointer hover:bg-[#CC9933] font-poppins animate-pulse bg-[#D4AF37] p-2 rounded-md text-white`}
                >
                  <p>Register</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="bg-[#D4AF37] w-full">
          <div className="px-4 mx-auto lg:px-24 flex md:px-8 justify-between w-full text-black">
            <p className="justify-end self-center gap-4 md:block hidden">
              email us at:{" "}
              <span className="hover:text-eskane-blue text-white">
                <ButtonMailto
                  label="service@eskane.com"
                  mailto="mailto:service@eskane.com"
                />
              </span>
            </p>
            <div className="self-center text-center">
              <p className="text-red-500 font-semibold text-center py-4 md:py-0">
                <span className="text-white">FIFA Countdown: </span>
                {timeBetween.years() !== 0 && (
                  <span>{timeBetween.years()}yr </span>
                )}
                {timeBetween.months() !== 0 && (
                  <span>{timeBetween.months()}m </span>
                )}
                {timeBetween.days() !== 0 && (
                  <span>{timeBetween.days()}d </span>
                )}
                <span>{timeBetween.hours()}h </span>
                <span>{timeBetween.minutes()}min </span>
                <span>{timeBetween.seconds()}s </span>
              </p>
            </div>
            <div onClick={openWhatsApp} className="flex justify-between gap-4">
              <FontAwesomeIcon
                className="bg-eskane-blue text-white rounded-full p-1 hover:bg-green-600 hover:cursor-pointer shadow-md self-center my-1 md:block hidden"
                icon={faWhatsapp}
              />
              <p className="justify-end self-center md:block hidden">
                call us at:{" "}
                <a
                  href="tel:+972049571191"
                  className="hover:text-eskane-blue text-white"
                >
                  +972 04-957-1191
                </a>
              </p>
            </div>
          </div>
        </div>
      </nav>
      <div className="mt-16 relative">{children}</div>
    </div>
  );
};
