import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";

import config from "../../../../config";

export const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const toastifySuccess = () => {
    toast("Message sent! We will get back to you shorlty.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  const onSubmit = async (data) => {
    const { first_name, last_name, email, console, size, number, message } =
      data;
    setIsLoading(true);

    try {
      const templateParams = {
        first_name,
        last_name,
        email,
        console,
        size,
        number,
        message,
      };

      await emailjs.send(
        config.serviceId,
        config.templateId,
        templateParams,
        config.userId
      );

      reset();
      toastifySuccess();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const inputStyle = "p-3 rounded-md mb-3 text-black bg-white";
  const errorStyle = "text-xs text-red-600";

  return (
    <div className="bg-eskane-blue rounded-lg shadow-xl p-4 mb-12">
      <div className="flex-1">
        <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="md:flex justify-between gap-2">
            <div className="pb-3 md:pb-0 grid w-full">
              <label className="text-white text-left">Name*</label>
              <input
                type="text"
                name="first_name"
                {...register("first_name", {
                  required: {
                    value: true,
                    message: "Please enter your First Name",
                  },
                  maxLength: {
                    value: 30,
                    message: "Please use 30 characters or less",
                  },
                })}
                className={inputStyle}
              />
              {errors.first_name && (
                <p className={errorStyle}>{`${errors.first_name.message}`}</p>
              )}
            </div>
            <div className="pb-3 md:pb-0 grid w-full">
              <label className="text-white text-left">Family Name*</label>
              <input
                type="text"
                name="last_name"
                {...register("last_name", {
                  required: {
                    value: true,
                    message: "Please enter your Family Name",
                  },
                  maxLength: {
                    value: 30,
                    message: "Please use 30 characters or less",
                  },
                })}
                className={inputStyle}
              />
              {errors.last_name && (
                <p className={errorStyle}>{`${errors.last_name.message}`}</p>
              )}
            </div>
          </div>
          <div className="grid w-full">
            <label className="text-white text-left">Email*</label>
            <input
              type="email"
              name="email"
              {...register("email", {
                required: true,
                pattern:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
              className={inputStyle}
            />
            {errors.email && (
              <p className={errorStyle}>Please enter a valid email address</p>
            )}
          </div>
          <div className="grid w-full">
            <label className="text-white text-left">Phone Number*</label>
            <input
              type="tel"
              name="number"
              {...register("number", {
                required: true,
              })}
              className={inputStyle}
            />
            {errors.number && (
              <p className={errorStyle}>Please enter a valid phone number</p>
            )}
          </div>
          <div className="pb-3">
            <div className="w-full grid">
              <label className="text-left text-white">Console*</label>
              <select
                name="console"
                {...register("console", {
                  required: { value: true, message: "Please enter a console" },
                })}
                className={inputStyle}
              >
                <option value="PC">PC</option>
                <option value="Playstation 4">Playstation 4</option>
                <option value="Playstation 5">Playstation 5</option>
                <option value="Xbox">XBOX</option>
              </select>
              {errors.console && (
                <span
                  className={errorStyle}
                >{`${errors.console.message}`}</span>
              )}
            </div>
          </div>
          <div className="pb-3">
            <div className="w-full grid">
              <label className="text-left text-white">T-Shirt Size*</label>
              <select
                name="size"
                {...register("size", {
                  required: {
                    value: true,
                    message: "Please enter a shirt size",
                  },
                })}
                className={inputStyle}
              >
                <option value="pc">SM</option>
                <option value="playstation">M</option>
                <option value="playstation">L</option>
                <option value="xbox">XL</option>
              </select>
              {errors.size && (
                <span className={errorStyle}>{`${errors.size.message}`}</span>
              )}
            </div>
          </div>
          <div>
            <div className="w-full grid">
              <label className="text-white text-left">Message</label>
              <textarea
                rows={3}
                name="message"
                {...register("message", {
                  required: false,
                })}
                className={inputStyle}
              />
              {errors.message && (
                <span className={errorStyle}>Please enter a message</span>
              )}
            </div>
          </div>
          <div className="flex justify-end">
            <button
              disabled={isLoading}
              className={`p-2 mt-8 ${
                isLoading ? "opacity-50" : "opacity-100"
              } text-white font-semibold border-white hover:text-eskane-black border-2 rounded-3xl px-12 outline-none hover:border-eskane-black`}
              type="submit"
            >
              Submit
            </button>
          </div>
          <div className="text-white text-center mt-4">
            <ToastContainer />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
