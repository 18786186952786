import React, { useEffect, useLayoutEffect } from "react";
import ReactGA from "react-ga";

import "./App.css";
import RootRouter from "./navigation/root.router";

const App: React.FC = () => {
  const TRACKING_ID = "G-PM606BSLDCE";
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useLayoutEffect(() => {
    document.body.style.backgroundColor = "white";
  });

  return <RootRouter />;
};

export default App;
