import React from "react";
import { BrowserRouter, Switch, useLocation } from "react-router-dom";
import _ from "lodash";

import { AppRouter, AppModalFormsRouter } from "./app";

const RootRouter: React.FC = () => {
  const Switcher = () => {
    const location = useLocation();
    const background =
      _.get(location, "state") && _.get(location, "state.background");

    const Routers = [AppRouter];

    return (
      <>
        <Switch location={background || location}>{Routers}</Switch>
        {background && <Switch>{AppModalFormsRouter}</Switch>}
      </>
    );
  };

  return (
    <BrowserRouter>
      <Switcher />
    </BrowserRouter>
  );
};

export default RootRouter;
