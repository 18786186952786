import { useEffect, useRef } from "react";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import { AppLayout, ContactForm, ScrollView } from "../../../components";

const ScrollToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const openWhatsApp = () => {
    const phoneNumber = "+972049571191";
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  return (
    <div className="fixed md:bottom-10 bottom-5 right-5 md:right-20 flex gap-2 z-50">
      <div onClick={scrollToTop}>
        <FontAwesomeIcon
          className="bg-eskane-blue text-white rounded-full md:p-3 p-5 border hover:bg-eskane-black hover:cursor-pointer shadow-lg"
          icon={faArrowUp}
        />
      </div>

      <div onClick={openWhatsApp}>
        <FontAwesomeIcon
          className="hover:bg-eskane-blue text-white rounded-full md:p-3 p-5 border bg-green-600 hover:cursor-pointer shadow-md"
          icon={faWhatsapp}
        />
      </div>
    </div>
  );
};

export const HomeScreen = () => {
  const handleContactMeClick = () => {
    document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
  };

  const getQuoteButtonRef = useRef(null);

  const handleScroll = () => {
    const buttonElement = getQuoteButtonRef.current;
    const servicesSection = document.getElementById("fifa");
    const homeSection = document.getElementById("home");

    if (buttonElement && servicesSection && homeSection) {
      const servicesOffsetTop = servicesSection.offsetTop;
      const homeOffsetTop = homeSection.offsetTop;

      const scrollTop = window.scrollY;

      if (
        window.innerWidth >= 768 &&
        scrollTop >= homeOffsetTop &&
        scrollTop <= servicesOffsetTop
      ) {
        buttonElement.style.top = `${scrollTop - homeOffsetTop}px`;
      } else {
        buttonElement.style.top = "";
      }

      if (window.innerWidth < 768 && scrollTop >= homeOffsetTop) {
        buttonElement.style.position = "fixed";
        buttonElement.style.bottom = "60px";
      } else {
        buttonElement.style.position = "";
        buttonElement.style.bottom = "";
      }
    }
  };

  const UpcomingMatches = () => {
    const MatchCard = ({
      date,
      player1,
      player2,
      score1,
      score2,
    }: {
      date: string;
      player1: string;
      player2: string;
      score1: string;
      score2: string;
    }) => (
      <div className="border-2 w-full pb-12 px-8">
        <p className="text-black pt-4 text-right font-bold">{date}</p>
        <div className="flex justify-between text-black pt-4">
          <div className="space-y-3">
            <p>{player1}</p>
            <p>{player2}</p>
          </div>
          <div className="space-y-3">
            <p>{score1}</p>
            <p>{score2}</p>
          </div>
        </div>
      </div>
    );
    return (
      <div className="bg-eskane-blue">
        <div className="rounded-tr-[100px] bg-white py-24 px-4">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-eskane-blue mb-4 text-3xl text-center">
              Matches
            </h1>

            <div className="bg-eskane-blue py-8 w-full px-4 rounded-t-lg">
              <h1>CUP NAME</h1>
              <p>date</p>
            </div>
            <div className="w-full shadow-2xl grid grid-cols-1 md:grid-cols-2">
              <MatchCard
                date="12/02/2023"
                player1="Adam Smith"
                player2="James Hunt"
                score1="1"
                score2="3"
              />
              <MatchCard
                date="12/02/2023"
                player1="Philp Smith"
                player2="Gary Fredricks"
                score1="2"
                score2="0"
              />
              <MatchCard
                date="13/02/2023"
                player1="Rani Arraf"
                player2="Sam Jents"
                score1="10"
                score2="3"
              />
              <MatchCard
                date="13/02/2023"
                player1="Tarek Abo Ouxa"
                player2="Harry Smith"
                score1="7"
                score2="2"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Leaderboard = () => {
    const headerStyle = "p-3 bg-eskane-blue border-white border text-white";
    const tdStyle = "p-3 text-black";

    return (
      <>
        <h1 className="text-eskane-blue mb-4 text-3xl text-center">
          Leaderboard
        </h1>
        <table className="border-collapse text-black w-full shadow-2xl rounded-xl">
          <thead>
            <tr>
              <th className={headerStyle}>eSkane Players</th>
              <th className={headerStyle}>W</th>
              <th className={headerStyle}>L</th>
              <th className={headerStyle}>D</th>
              <th className={headerStyle}>GP</th>
              <th className={headerStyle}>Ranking</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={tdStyle}>Gary Harden</td>
              <td className={tdStyle}>11</td>
              <td className={tdStyle}>2</td>
              <td className={tdStyle}>0</td>
              <td className={tdStyle}>14</td>
              <td className={tdStyle}>1st</td>
            </tr>
            <tr>
              <td className={tdStyle}>James Hunt</td>
              <td className={tdStyle}>10</td>
              <td className={tdStyle}>3</td>
              <td className={tdStyle}>0</td>
              <td className={tdStyle}>14</td>
              <td className={tdStyle}>2nd</td>
            </tr>
            <tr>
              <td className={tdStyle}>Adam Smithn</td>
              <td className={tdStyle}>9</td>
              <td className={tdStyle}>2</td>
              <td className={tdStyle}>2</td>
              <td className={tdStyle}>13</td>
              <td className={tdStyle}>3rd</td>
            </tr>
            <tr>
              <td className={tdStyle}>Tarek Abo Ouxa</td>
              <td className={tdStyle}>8</td>
              <td className={tdStyle}>5</td>
              <td className={tdStyle}>2</td>
              <td className={tdStyle}>14</td>
              <td className={tdStyle}>4th</td>
            </tr>
            <tr>
              <td className={tdStyle}>Rani Arraf</td>
              <td className={tdStyle}>7</td>
              <td className={tdStyle}>5</td>
              <td className={tdStyle}>3</td>
              <td className={tdStyle}>14</td>
              <td className={tdStyle}>5th</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const HomeBackground = require("../../../assets/images/duck3.jpeg");

  return (
    <AppLayout toggle="home">
      <ScrollToTop />

      <div id="home" className="px-4 py-48 bg-eskane-black relative">
        <img
          src={HomeBackground}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover md:object-cover opacity-50"
        />
        <div className="max-w-4xl mx-auto relative z-10 text-center md:text-left text-white">
          <h1 className="text-4xl mb-6">
            Welcome to{" "}
            <span className="text-eskane-blue font-poppins">eSkane</span>
          </h1>
          <p className="break-words text-sm md:text-base mt-2 ml-2 font-poppins tracking-wider">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries.
          </p>
        </div>
      </div>
      <section
        id="fifa"
        className="text-center md:text-left text-white bg-eskane-blue"
      >
        <div className="mx-auto py-12 max-w-4xl pt-32">
          <h1 className="text-4xl mb-2">FIFA TOURNAMENT</h1>
          <p className="text-sm md:text-base font-poppins text-white">
            Join us for the upcoming FIFA Tournament!
          </p>
        </div>
      </section>
      <div id="fifa" className="text-center md:text-left text-white bg-white">
        <UpcomingMatches />

        <div className="h-96 md:bg-fifa bg-player3 bg-fixed bg-cover bg-opacity-20 overflow-auto flex z-20 relative md:rounded-l-[100px] md:ml-24">
          <div className="text-xl font-bold text-white self-center justify-center text-center w-full relative md:mr-24">
            <ScrollView>
              <div className="max-w-4xl mx-auto relative text-center md:text-left text-white">
                <h1 className="text-4xl mb-6">
                  JOIN THE{" "}
                  <span className="text-eskane-blue font-poppins">
                    FIFA TOURNAMENT
                  </span>
                </h1>
                <p className="break-words text-sm md:text-base mt-2 tracking-wider">
                  Be part of the fun and have a chance to win amazing prizes!
                </p>
                <div className="mt-6 text-center md:text-right">
                  <button
                    className="bg-eskane-blue text-white px-8 py-3 rounded-md md:ml-4 hover:bg-eskane-black animate-pulse mt-8"
                    onClick={handleContactMeClick}
                  >
                    JOIN NOW
                  </button>
                </div>
              </div>
            </ScrollView>
          </div>
        </div>
        <div className="max-w-4xl mx-auto py-24 px-4">
          <Leaderboard />
        </div>
      </div>

      <section className="h-96 md:bg-business bg-player bg-fixed bg-cover bg-no-repeat overflow-auto flex z-20 relative md:rounded-r-[100px] md:mr-24 md:mb-8">
        <div className="text-xl font-bold text-white self-center justify-center text-center w-full relative md:ml-24">
          <ScrollView>
            <div className="max-w-4xl mx-auto relative text-center md:text-left text-white">
              <h1 className="text-4xl mb-6">
                CONNECT WITH{" "}
                <span className="text-eskane-blue font-poppins">US</span>
              </h1>
              <p className="break-words text-sm md:text-base mt-2 tracking-wider">
                1500s, when an unknown printer took a galley of type and
                scrambled it to make a type
              </p>
              <div className="mt-12 text-center md:text-right">
                <button
                  className="bg-eskane-blue text-white px-8 py-3 rounded-md md:ml-4 hover:bg-eskane-black mt-8"
                  onClick={handleContactMeClick}
                >
                  Register With Us
                </button>
              </div>
            </div>
          </ScrollView>
        </div>
      </section>

      <div className="bg-eskane-blue w-full h-60 md:block hidden">
        <div className="w-full h-60 bg-white rounded-br-[100px]" />
      </div>

      <div className="px-4 py-24 bg-eskane-blue flex justify-center">
        <div className="text-center md:text-center text-white max-w-4xl">
          <h1 className="text-2xl mb-2 text-white">
            eSkane is the pinical for modern day IT solutions
          </h1>
          <h1 className="text-2xl mb-2 text-white">
            <span className="text-eskane-black font-poppins mr-2">eskane</span>
            is here to create a dream
          </h1>
        </div>
      </div>

      <div className="bg-eskane-blue w-full h-24">
        <div className="w-full h-24 bg-white rounded-tl-[100px]" />
      </div>

      <div
        id="contact"
        className="w-full px-4 md:px-0 mx-auto bg-white relative"
      >
        <div className="max-w-4xl mx-auto text-center md:text-left text-white">
          <div className="w-full text-black font-black text-6xl md:mt-24 my-12 text-center">
            <p>
              Join the fun{" "}
              <span className="text-eskane-blue">and play ball</span>
            </p>
          </div>
          <div className="md:w-full md:pt-12">
            <ContactForm />
          </div>
        </div>
      </div>
    </AppLayout>
  );
};
