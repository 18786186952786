import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const Footer = () => {
  const openWhatsApp = () => {
    const phoneNumber = "+972049571191";
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  const ButtonMailto = ({ mailto, label }) => {
    return (
      <Link
        to="#"
        onClick={(e) => {
          window.location.href = mailto;
          e.preventDefault();
        }}
      >
        <p className="text-blue-400 hover:text-blue-400">{label}</p>
      </Link>
    );
  };
  const _home = () => {
    document.getElementById("home").scrollIntoView({
      behavior: "smooth",
    });
  };

  const _contact = () => {
    document.getElementById("contact").scrollIntoView({
      behavior: "smooth",
    });
  };

  const _fifa = () => {
    document.getElementById("fifa").scrollIntoView({
      behavior: "smooth",
    });
  };

  const linkStyle = "hover:cursor-pointer";
  const Logo = require("../../../assets/images/logo.png");

  return (
    <>
      <div className="text-white bg-white border-t-2 border-eskane-blue mb-24">
        <div className="md:grid grid-cols-4 grid-flow-col gap-3 px-4 py-8 place-items-center">
          <div onClick={_home}>
            <img
              src={Logo}
              alt="eskane"
              className="w-56 hover:cursor-pointer ml-[-8px]"
            />
          </div>
          <div>
            <h1 className="font-bold text-xl text-eskane-blue mt-8">
              Social Media
            </h1>
            <ul className="text-black flex gap-2">
              <li onClick={openWhatsApp}>
                <FontAwesomeIcon
                  className="bg-eskane-blue text-white rounded-full p-2 hover:bg-green-600 hover:cursor-pointer shadow-md"
                  icon={faWhatsapp}
                />
              </li>
              <li>
                <a href="https://www.facebook.com/eSkaneGlobal/">
                  <FontAwesomeIcon
                    className="bg-eskane-blue text-white rounded-full p-2 hover:bg-[#1877F2] hover:cursor-pointer shadow-md"
                    icon={faFacebook}
                  />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/eskane/?originalSubdomain=il">
                  <FontAwesomeIcon
                    className="bg-eskane-blue text-white rounded-full p-2 hover:bg-[#0a66c2] hover:cursor-pointer shadow-md"
                    icon={faLinkedin}
                  />
                </a>
              </li>
              <li>
                <a href="">
                  <FontAwesomeIcon
                    className="bg-eskane-blue text-white rounded-full p-2 hover:bg-[#C13584] hover:cursor-pointer shadow-md"
                    icon={faInstagram}
                  />
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h1 className="font-bold text-xl mt-8 text-eskane-blue">Links</h1>
            <ul className="text-black">
              <li className={linkStyle}>
                <a onClick={_home}>Home</a>
              </li>
              <li className={linkStyle} onClick={_fifa}>
                FIFA Tournament
              </li>
              <li className={linkStyle} onClick={_contact}>
                Register
              </li>
            </ul>
          </div>
          <div>
            <h1 className="font-bold text-xl text-eskane-blue mt-8">Support</h1>
            <ul>
              <li className="overflow-hidden">
                <ButtonMailto
                  label="service@eskane.com"
                  mailto="mailto:service@eskane.com"
                />
              </li>
              <li className="overflow-hidden text-black">
                <a href="tel:+972049571191">+972 04-957-1191</a>
              </li>
              <li className="overflow-hidden text-black md:max-w-[130px]">
                <p>unnamed road, 2513500 Miilya, Israel</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="lg:h-8 h-12 bg-white md:grid grid-cols-4 grid-flow-col gap-3 px-4 place-items-center text-xs">
        <div className="text-gray-500">
          <p className="text-gray-500">© COPYRIGHT 2023. ALL RIGHTS RESERVED</p>
        </div>
        <div />
        <div />
        <div className="md:text-right text-left">
          <p className="text-gray-500">
            WEBSITE DESIGN BY <span className="text-eskane-blue">eSkane</span>
          </p>
        </div>
      </div>
    </>
  );
};
